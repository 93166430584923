import { useEffect, useState } from 'react'
import FormLogic from './FormLogic'
import FacilityService from 'services/facility-service'
import { useUserContext } from 'context/UserContext'
import { SaivaUser, UserCapability } from 'types/user-types'
import UserService from 'services/user-service'
import RegionService from 'services/region-service'
import InvitationService from 'services/invitation-service'
import { handleError } from 'utils/errorHandler'
import { mixpanelInstance } from 'utils/mixpanel'
import { showSuccessToast } from 'utils'
import { useTranslation } from 'react-i18next'
import { SaivaInvitation } from 'types/invitation-types'
import WoundCareService from 'services/wound-care-service'

const FormApi = ({
  formState,
  closeModal,
  editingUser,
  isClosed,
  editInvitation
}) => {
  const { t } = useTranslation()
  const userContext = useUserContext()
  const [roles, setRoles] = useState<SaivaUser.Role[]>([])
  const [titles, setTitles] = useState<SaivaUser.Title[]>([])
  const [facilities, setFacilities] = useState<any>([])
  const [regions, setRegions] = useState<any>([])
  const [apiError, setApiError] = useState<any>(undefined)

  useEffect(() => {
    setApiError(undefined)
  }, [isClosed])

  useEffect(() => {
    if (editingUser) {
      editingUser = {}
    }
    setApiError(undefined)
    fetchRegions()
  }, [editingUser, userContext.currentOrg.id])

  const fetchRegions = async () => {
    await RegionService.getRegions(userContext.currentOrg.id)
      .then((resp) => {
        setRegions(
          resp?.map((r) => {
            return {
              value: r.id,
              label: r.name,
              children: r.facilities.map((g) => {
                return { value: g.id, label: g.name, parent: r.id }
              })
            }
          })
        )
        fetchFacilities()
        fetchRoles()
        fetchTitles()
      })
      .catch((err) => {
        setRegions([])
        handleError({ id: 'FetchTitles', error: err })
      })
  }

  const fetchRoles = async () => {
    try {
      const roles = await UserService.getRoles(userContext.currentOrg.id)
      if (roles) setRoles(roles)
    } catch (e) {
      setRoles([])
      handleError({ id: 'FetchRoles', error: e })
    }
  }

  const fetchFacilities = async () => {
    try {
      const facilities = await FacilityService.getAllFacilities(
        userContext.currentOrg.id
      )
      if (facilities)
        setFacilities(
          facilities.map((item) => {
            return { value: item.id, label: item.name, parent: item.region_id }
          })
        )
    } catch (e) {
      setFacilities([])
      handleError({ id: 'FetchFacilities', error: e })
    }
  }

  const fetchTitles = async () => {
    try {
      const titles = await UserService.getTitles(userContext.currentOrg.id, {
        role_category: userContext.currentRole
      })
      if (titles) setTitles(titles)
    } catch (e) {
      setTitles([])
      handleError({ id: 'FetchTitles', error: e })
    }
  }

  const handleDelete = async () => {
    closeModal()
  }

  const isEdit = editingUser ? true : false

  const handleSubmit = async (data: SaivaInvitation.Request) => {
    const enabled = editingUser?.roles[0].enabled

    const selectedRegions = data.regions.map((i) => {
      return i.region_id
    })
    const selectedFacilities = data.regions
      .map((i) => {
        return i.facility_ids
      })
      .flat(1)
    let message = {
      invitation_org_id: userContext.currentOrg.id,
      invitation_user_id: editingUser ? editingUser.id : undefined,
      invitation_email: data.email,
      invitation_name: data.name,
      invitation_title: data.title,
      invitation_role: data.roles.map((item) => {
        return item.name
      }),
      invitation_capabilities_pushNotifications: data.capabilities.includes(
        UserCapability.PUSH_NOTIFICATION
      ),
      invitation_capabilities_emailNotifications: data.capabilities.includes(
        UserCapability.EMAIL_NOTIFICATION
      ),
      invitation_capabilities_mobileAppLogin: data.capabilities.includes(
        UserCapability.MOBILE_APP_LOGIN
      ),
      invitation_capabilities_webAppLogin: data.capabilities.includes(
        UserCapability.WEB_APP_LOGIN
      ),
      regions: regions
        .filter((i) => selectedRegions.includes(i.value))
        .map((i) => {
          return i.label
        }),
      regions_ids: regions
        .filter((i) => selectedRegions.includes(i.value))
        .map((i) => {
          return i.value
        }),
      facilities: facilities
        .filter((i) => selectedFacilities.includes(i.value))
        .map((i) => {
          return i.label
        }),
      facilities_ids: facilities
        .filter((i) => selectedFacilities.includes(i.value))
        .map((i) => {
          return i.value
        })
    }

    const submitDataEdit = {
      email: data.email,
      name: data.name,
      title: data.title ? data.title : 'Other',
      regions: {
        full_access:
          selectedFacilities.length == facilities.length ? true : false,
        items: data.regions
      },
      roles: data.roles?.map((item) => {
        return {
          role_name: item.name,
          enabled: enabled
        }
      }),
      capabilities: data.capabilities
    }

    const submitDataInvitation = {
      email: data.email,
      name: data.name,
      title: data.title ? data.title : 'Other',
      regions: {
        full_access:
          selectedFacilities.length == facilities.length ? true : false,
        items: data.regions
      },
      roles: data.roles?.map((item) => {
        return {
          role_name: item.name,
          enabled: enabled
        }
      }),
      capabilities: data.capabilities
    }
    if (isEdit) {
      if (editInvitation) {
        await InvitationService.updateInvitation(
          userContext.currentOrg.id,
          editingUser.id,
          submitDataInvitation
        )
          .then((resp) => {
            closeModal()
            mixpanelInstance.usersEdit(message)
            showSuccessToast(
              t('userForm.updateUserSuccessText', { email: data.email })
            )
          })
          .catch((err) => {
            if (err.name === 'ApiError') {
              setApiError(err.errorCode)
            } else {
              setApiError('genericError')
            }
            throw err
          })
      } else {
        await UserService.updateUser(
          userContext.currentOrg.id,
          editingUser.id,
          submitDataEdit
        )
          .then((resp) => {
            closeModal()
            mixpanelInstance.usersEdit(message)
            showSuccessToast(
              t('userForm.updateUserSuccessText', { email: data.email })
            )
          })
          .catch((err) => {
            if (err.name === 'ApiError') {
              setApiError(err.errorCode)
            } else {
              setApiError('genericError')
            }
            throw err
          })
      }
    } else {
      if (userContext.currentRole === 'medical_supply') {
        await WoundCareService.sendInvitationWoundCare(
          userContext.currentMedicalSupplyOrg.id,
          userContext.currentOrg.id,
          submitDataInvitation
        )
          .then((resp) => {
            mixpanelInstance.usersInvitation(message)
            closeModal()
            showSuccessToast(
              t('userForm.inviteUserSuccessText', { email: data.email })
            )
          })
          .catch((err) => {
            if (err.name === 'ApiError') {
              setApiError(err.errorCode)
            } else {
              setApiError('genericError')
            }
            throw err
          })
      } else {
        await InvitationService.createInvitation(
          userContext.currentOrg.id,
          submitDataInvitation
        )
          .then((resp) => {
            mixpanelInstance.usersInvitation(message)
            closeModal()
            showSuccessToast(
              t('userForm.inviteUserSuccessText', { email: data.email })
            )
          })
          .catch((err) => {
            if (err.name === 'ApiError') {
              setApiError(err.errorCode)
            } else {
              setApiError('genericError')
            }
            throw err
          })
      }
    }
  }

  const regionsOption =
    regions &&
    regions.map((i) => {
      return {
        region_id: i.value,
        all_facilities: true,
        facility_ids: i.children.map((j) => j.value)
      }
    })

  const defaultValues = {
    id: editingUser?.id ?? 0,
    email: editingUser?.email ?? '',
    name: editingUser?.name ?? '',
    roles: editingUser?.roles ?? [],
    title: editingUser?.title ?? null,
    capabilities: editingUser?.capabilities ?? [],
    regions: editingUser?.regions.full_access
      ? regionsOption
      : editingUser?.regions.items ?? [],
    is_superuser: editingUser?.is_superuser ?? false
  }

  const options = {
    roles: roles,
    titles: titles,
    facilities: facilities,
    regions: regions
  }

  return (
    <FormLogic
      defaultValues={defaultValues}
      options={options}
      isEdit={isEdit}
      apiError={apiError}
      isClosed={isClosed}
      formState={formState}
      onSubmit={handleSubmit}
      handleDelete={handleDelete}
    />
  )
}

export default FormApi
