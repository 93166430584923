import React, { useState, useRef, RefObject, useEffect } from 'react'
import { Modal, Space, Divider, Row, Col } from 'antd'
import { ReactComponent as CrossIcon } from 'assets/icons/cross-big.svg'
import SRModalApi from './SRModalApi'
import ConfirmationModal from '../ConfirmationModal/CModal'
import styles from './SRModal.module.scss'
import { Schedules } from 'types/schedule-reports'
import { getReportLabel } from 'utils/helper'
import { getFeatureLabel } from 'utils/helper'
import { Tooltip } from 'antd'

type FiltersType = {
  date?: {
    start: string
    end: string
    isQuarterly: boolean
    type: string
  }
  quality_measure?: string
  filter_30_days?: boolean
  los_range?: boolean
  include_hospice?: boolean
  outcome?: string[]
  planned?: string[]
  facilities?: string[]
  facility_ids?: number[]
  payer_type?: string[]
  predicted?: string[]
  type?: string
  covid_diagnosed?: string[]
  payers: string[]
  physicians: string[]
}

type ReportNameConfig = {
  label: string
  color: string
  bg: string
}

type HeaderProps = {
  isEditMode?: boolean
  isDuplicate?: boolean
  reportName?: ReportNameConfig | null
  filtersData?: FiltersType
}

function Filters({ filtersData }: { filtersData: FiltersType | undefined }) {
  const {
    facilities,
    outcome,
    planned,
    filter_30_days,
    los_range,
    payer_type,
    include_hospice,
    predicted,
    covid_diagnosed,
    payers,
    physicians
  } = filtersData || {}

  const modalRef = useRef<HTMLDivElement>(null)

  function getLength(divRef: RefObject<HTMLDivElement[]>) {
    if (divRef.current && modalRef.current) {
      const modalWidth = modalRef.current.offsetWidth
      let accumulatedWidth = 0
      for (let i = 0; i < divRef.current.length; i++) {
        accumulatedWidth = divRef.current[i].offsetWidth + accumulatedWidth
        if (accumulatedWidth > modalWidth) {
          return i - 1
        }
      }
    } else return undefined
  }

  function getListOfFilters(label: string, list: string[]) {
    const divRef = useRef<HTMLDivElement[]>([])
    const [length, setLength] = useState<number>(list.length)

    useEffect(() => {
      const length = getLength(divRef)
      if (length) {
        setLength(length)
      }
    }, [list])

    function filterItem(item: string, index: number) {
      return (
        <div
          key={index}
          ref={(el) => {
            if (divRef.current && el) {
              divRef.current[index] = el
            }
          }}
          className={styles.filterItem}
        >
          <label className={styles.facilityLabel}>{item}</label>
        </div>
      )
    }

    return (
      <div
        data-testid={`filterList-${label}`}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div className={styles.filterValues}>
          <div className={styles.label}>{label}</div>
          {list.length === 0 && filterItem('All', 0)}
          {Array.isArray(list)
            ? list
                .slice(0, length)
                .map((item, index) => filterItem(item, index))
            : filterItem(list, 0)}
        </div>
        {list.length > length && (
          <div
            style={{
              marginLeft: '8px',
              color: '#11244399',
              fontSize: '11px'
            }}
          >
            <Tooltip
              title={
                <div className={styles.tooltip}>
                  {list.slice(length).map((facility) => (
                    <div>{facility}</div>
                  ))}
                </div>
              }
              arrow={false}
              overlayInnerStyle={{
                background: '#112443',
                textAlign: 'left'
              }}
            >
              + {list.length - length} more
            </Tooltip>
          </div>
        )}
      </div>
    )
  }

  function getLabelOfFilters(label: string, value: string) {
    return (
      <div style={{ display: 'inline-block' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '8px', color: '#11244399' }}>{label}</div>
          <div className={styles.filterLabel}>{value}</div>
        </div>
      </div>
    )
  }

  return (
    <div
      ref={modalRef}
      style={{ display: 'flex', gap: '6px 28px', flexWrap: 'wrap' }}
    >
      {facilities && getListOfFilters('Facilities', facilities)}
      {outcome && getListOfFilters('Outcome', outcome)}
      {planned && getListOfFilters('Planned', planned)}
      {predicted && getListOfFilters('Predicted', predicted)}
      {payer_type && getListOfFilters('Payers', payer_type)}
      {covid_diagnosed && getListOfFilters('Covid Diagnosed', covid_diagnosed)}
      {filter_30_days != undefined &&
        getLabelOfFilters('LOS Range', filter_30_days ? '1 - 30 Days' : 'All')}
      {los_range != undefined &&
        getLabelOfFilters('LOS Range', los_range ? '1 - 30 Days' : 'All')}
      {include_hospice != undefined &&
        getLabelOfFilters('Include Hospice', include_hospice ? 'Yes' : 'No')}
      {payers && getListOfFilters('Payers', payers)}
      {physicians && getListOfFilters('Physicians', physicians)}
    </div>
  )
}

function Header({
  isEditMode,
  isDuplicate,
  reportName,
  filtersData
}: HeaderProps) {
  return (
    <Row gutter={[16, 16]} align="top">
      <Col span={24}>
        <Space direction="horizontal" wrap align="start">
          <div
            style={{
              flex: 'none',
              order: 0,
              flexGrow: 0
            }}
          >
            <h4>
              {isEditMode ? 'Edit' : isDuplicate ? 'Duplicate' : 'New'} Schedule
            </h4>
          </div>
          <label
            className="category-label"
            style={{
              color: reportName?.color,
              background: reportName?.bg,
              fontSize: '14px',
              marginLeft: '60px'
            }}
          >
            {reportName?.label}
          </label>
          {filtersData?.quality_measure && (
            <>
              <label className={styles.qualityMeasure}>Quality Measure</label>
              <label className={styles.qualityMeasureValue}>
                {getFeatureLabel(filtersData?.quality_measure).label}
              </label>
            </>
          )}
        </Space>
      </Col>
    </Row>
  )
}

const ScheduleReportingModal = ({
  reportId,
  filtersData,
  isEditMode,
  isDuplicate,
  open,
  item,
  deleteItem,
  close
}: {
  reportId?: Schedules.ReportTypes
  filtersData?: FiltersType | undefined
  isEditMode?: boolean
  isDuplicate?: boolean
  open?: boolean
  item?: any
  deleteItem?: () => void
  close: () => void
}) => {
  const [openConfirm, setOpenConfirm] = useState(false)
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const [changed, setChanged] = useState(false)
  const [isClosed, setIsClosed] = useState<boolean>(false)

  const reportName = getReportLabel(reportId)

  const dateType = filtersData
    ? filtersData?.type == 'day'
      ? 'daily'
      : filtersData?.type == 'quarterly'
      ? 'monthly'
      : filtersData?.type
    : 'monthly'

  const isDirty = (value) => {
    setChanged(value)
  }

  const handleDelete = () => {
    setOpenDeleteConfirm(true)
  }

  const handleDeleteConfirm = () => {
    deleteItem ? deleteItem() : null
    setOpenDeleteConfirm(false)
  }

  const handleDeleteCancel = () => {
    setOpenDeleteConfirm(false)
  }

  const closeModal = () => {
    if (changed) {
      setOpenConfirm(true)
    } else {
      setIsClosed(!isClosed)
      close()
    }
  }

  const closeModalSave = () => {
    setIsClosed(!isClosed)
    close()
  }

  const handleOk = () => {
    setIsClosed(!isClosed)
    setOpenConfirm(false)
    close()
  }

  const handleCancelConfirm = () => {
    setOpenConfirm(false)
  }

  return (
    <>
      <ConfirmationModal
        openConfirm={openConfirm}
        handleCancelConfirm={handleCancelConfirm}
        handleOk={handleOk}
        title={'You have unsaved changes.'}
        message={'Do you want to save changes before closing the modal window?'}
      />
      <ConfirmationModal
        openConfirm={openDeleteConfirm}
        handleCancelConfirm={handleDeleteCancel}
        handleOk={handleDeleteConfirm}
        title={'Delete a schedule'}
        message={
          'Do you really want to delete the schedule? This operation is irreversible and you will lost all schedule settings.'
        }
      />
      <Modal
        closeIcon={<CrossIcon style={{ padding: '2px' }} />}
        width={'1042px'}
        footer={null}
        open={open}
        onCancel={closeModal}
      >
        <Header
          isEditMode={isEditMode}
          isDuplicate={isDuplicate}
          reportName={reportName}
          filtersData={filtersData}
        />
        <SRModalApi
          closeModal={closeModalSave}
          isDirty={isDirty}
          isEditMode={isEditMode}
          isDuplicate={isDuplicate}
          isClosed={isClosed}
          deleteItem={handleDelete}
          schedule={item}
          filtersData={filtersData}
          reportId={reportId}
          dateType={dateType}
        >
          {filtersData && <Filters filtersData={filtersData} />}
          <Divider className={styles.divider} />
        </SRModalApi>
      </Modal>
    </>
  )
}

export default ScheduleReportingModal
