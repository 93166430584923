import React, { useEffect, useState, useLayoutEffect } from 'react'
import { Divider, Button, Progress, Modal, Space } from 'antd'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as CheckboxIcon } from 'assets/icons/checkbox.svg'
import { showErrorToast } from 'utils'
import styles from './OptionsSelect.module.scss'
import SaivaCheckbox from 'components/SaivaForm/SaivaCheckbox/SaivaCheckbox'
import { useTranslation } from 'react-i18next'
import SaivaSearch from 'components/SaivaForm/Search/SaivaSearch'
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation'

interface ComponentProps {
  items: { id: string; label: string }[]
  onChange: (value: any) => void
  selectedItems?: { id: string; label: string }[]
  loading?: boolean
  label?: string
  color?: string
}

const FacilitiesSelectCard = ({
  onChange,
  items,
  selectedItems,
  loading,
  label,
  color
}: ComponentProps) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState<string>('')
  const ref = React.useRef<HTMLDivElement>(null)
  const [selectedItemsState, setSelectedItemsState] = useState<any>([])
  const [modalSelectedItemsState, setModalSelectedItemsState] = useState<any>(
    []
  )

  useEffect(() => {
    if (selectedItems) {
      setSelectedItemsState(selectedItems)
    }
  }, [selectedItems])

  useEffect(() => {
    setModalSelectedItemsState(selectedItemsState)
  }, [selectedItemsState])

  useEffect(() => {
    if (
      items &&
      selectedItems &&
      items
        .map((i) => i.id)
        .every((v) => selectedItems.map((j) => j.id).includes(v))
    ) {
      setSelectedItemsState(items)
    }
  }, [items])

  const closeModal = () => {
    if (modalSelectedItemsState.length === 0) {
      showErrorToast(t('optionSelect.error.oneOrMoreItem'))
    } else {
      setSearch('')
      setIsOpen(false)
    }
  }

  const handleRemoveItem = (item) => {
    if (selectedItemsState.map((i) => i.id).includes(item.id)) {
      {
        if (selectedItemsState.length > 1) {
          setSelectedItemsState(
            selectedItemsState.filter((i) => {
              return i.id !== item.id
            })
          )
          onChange(selectedItemsState.filter((i) => i.id !== item.id))
        } else {
          showErrorToast(t('optionSelect.error.oneOrMoreItem'))
        }
      }
    } else {
      setSelectedItemsState([...selectedItemsState, item])
      onChange([...selectedItemsState, item])
    }
  }

  const handleModalRemoveItem = (item) => {
    {
      if (modalSelectedItemsState.map((i) => i.id).includes(item.id)) {
        {
          if (modalSelectedItemsState.length > 1) {
            setModalSelectedItemsState(
              modalSelectedItemsState.filter((i) => {
                return i.id !== item.id
              })
            )
          } else {
            showErrorToast(t('optionSelect.error.oneOrMoreItem'))
          }
        }
      } else {
        setModalSelectedItemsState([...modalSelectedItemsState, item])
      }
    }
  }

  const selectedLastItems = items.filter((item) => {
    return !selectedItemsState.map((i) => i.id).includes(item.id)
  })

  const filterItems = [...selectedItemsState, ...selectedLastItems]

  return (
    <>
      {items && (
        <div className={styles.box}>
          <Modal
            closeIcon={<CloseIcon />}
            width={'384px'}
            footer={
              <>
                <Divider className={styles.fullWidthDivider} />
                <div className={styles.footerModal}>
                  <Button
                    onClick={() => {
                      closeModal()
                      onChange(modalSelectedItemsState)
                      setSelectedItemsState(modalSelectedItemsState)
                    }}
                    disabled={modalSelectedItemsState.length === 0}
                    className={styles.applyButton}
                  >
                    Apply
                  </Button>
                </div>
              </>
            }
            wrapClassName={styles.modal}
            open={isOpen}
            onCancel={closeModal}
          >
            <div style={{ padding: '50px 20px 0px 20px' }}>
              <SaivaSearch
                placeholder={t('search.text')}
                filters={false}
                activeFilters={false}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                value={search}
                content={<></>}
              />
              <div className={styles.selectAll}>
                <SaivaCheckbox
                  checked={modalSelectedItemsState.length === items.length}
                  all={true}
                  onChange={() => {
                    if (modalSelectedItemsState.length === items.length) {
                      setModalSelectedItemsState([])
                    } else {
                      setModalSelectedItemsState(items)
                    }
                  }}
                />
                <div className={`${styles.facilityNameModal} elipsis`}>
                  Select All
                </div>
              </div>
            </div>
            <Divider className={styles.fullWidthDivider} />
            <div className={styles.facilitiesList}>
              {items.length > 0 ? (
                <ul style={{ padding: '0px' }}>
                  {items.map((item) => {
                    if (
                      item.label.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return (
                        <li key={item.id} className={styles.checkbox}>
                          <SaivaCheckbox
                            checked={modalSelectedItemsState
                              .map((i) => i.id)
                              .includes(item.id)}
                            onChange={() => handleModalRemoveItem(item)}
                          />
                          <div
                            className={`${styles.facilityNameModal} elipsis`}
                          >
                            {item.label}
                          </div>
                        </li>
                      )
                    }
                  })}
                </ul>
              ) : (
                <div className={styles.noResults}>No results found</div>
              )}
            </div>
          </Modal>
          <table className={styles.header}>
            <tbody>
              <tr>
                <td>
                  <h5>{label}</h5>
                </td>
                <td style={{ textAlign: 'right' }}>
                  <Button
                    className={styles.actionButton}
                    onClick={() => setIsOpen(true)}
                    disabled={loading || items.length === 0}
                  >
                    <EditIcon />
                    <Space style={{ paddingLeft: '10px' }}>Manage</Space>
                  </Button>
                </td>
              </tr>
              <tr>
                <td>
                  <small>
                    Showing {selectedItemsState.length}/{items.length}
                  </small>
                </td>
                <td>
                  <div
                    style={{
                      width: 80,
                      marginRight: '10px',
                      marginLeft: 'auto'
                    }}
                  >
                    <Progress
                      strokeColor={color}
                      trailColor={color + '20'}
                      percent={(selectedItemsState.length / items.length) * 100}
                      showInfo={false}
                      size="small"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Divider style={{ margin: '8px 0px' }} />
          {loading ? (
            <LoadingAnimation />
          ) : (
            <div className={styles.facilities}>
              <div ref={ref}>
                {selectedItemsState.length > 4 ? (
                  <ul>
                    {selectedItemsState.sort().map((item) => {
                      return (
                        <li key={item.id} className={styles.listItem}>
                          <div
                            className={styles.dot}
                            style={{ background: color }}
                          ></div>
                          <div className={`${styles.facilityName} elipsis`}>
                            {item.label}
                          </div>
                          <Button
                            icon={<CrossIcon />}
                            type="text"
                            onClick={() => handleRemoveItem(item)}
                          />
                        </li>
                      )
                    })}
                  </ul>
                ) : items.length > 0 ? (
                  <ul>
                    {filterItems
                      .sort()
                      .slice(0, 4)
                      .map((item) => {
                        return (
                          <li key={item.id} className={styles.listItem}>
                            <div
                              className={styles.dot}
                              style={{ background: color }}
                            ></div>
                            <div className={`${styles.facilityName} elipsis`}>
                              {item.label}
                            </div>
                            <Button
                              icon={
                                selectedItemsState
                                  ?.map((i) => i.id)
                                  ?.includes(item.id) ? (
                                  <CrossIcon />
                                ) : (
                                  <CheckboxIcon />
                                )
                              }
                              type="text"
                              onClick={() => handleRemoveItem(item)}
                            />
                          </li>
                        )
                      })}
                  </ul>
                ) : (
                  <div className={styles.noResults}>No Selected item</div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default FacilitiesSelectCard
