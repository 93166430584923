import { IReportRequestProps, QueryTypes } from '../report-service'
import { UserFilter } from '../user-service'
import { WoundsFilter, WoundCareAction } from '../wound-care-service'
import { UserCapability } from '../../types/user-types'
import { ISaivaDetailedUser } from '../api'
import { ScheduleFilter } from 'types/schedule-reports'
import { handleError } from '../../utils/errorHandler'
import { ResidentFilter, RiskScoreChartFilter } from '../resident-service'

export enum SortDirection {
  Ascending = "asc",
  Descending = "desc",
}

export type SaivaSort = {
  property: string
  direction: SortDirection
}

// TODO merge with generateReportUrl
export interface RequestFilter {
  search?: string,
  facilitySearch?: string,
  page?: number,
  size?: number,
  sort?: SaivaSort,
  active?: boolean
  enabled?: boolean
  hidden_columns?: string[]
  physicians?: string[]
}

export interface PaginatedResponse<D> {
  items: D[]
  total: number,
  page: number,
  size: number
}

export const parseApi = {
  Pagination: function a<R, D> (response: PaginatedResponse<R>, parseFunction: (item: R) => D = (v) => v as unknown as D) : PaginatedResponse<D> {
    return {...response, items: response.items.map(parseFunction)}
  },
  UserToDetailedUser: (user: any) : ISaivaDetailedUser => {
    const selectedFacilities = user.regions.items
      .map((i) => {
        return i.facility_ids
      })
      .flat(1)

    return {
      id: user.id,
      email: user.email,
      name: user.name,
      title: user.title,
      last_active_at: user.last_active_at,
      capabilities: {
        emailNotifications: user.capabilities.includes(UserCapability.EMAIL_NOTIFICATION),
        pushNotifications: user.capabilities.includes(UserCapability.PUSH_NOTIFICATION),
        webAppLogin: user.capabilities.includes(UserCapability.WEB_APP_LOGIN),
        mobileAppLogin: user.capabilities.includes(UserCapability.MOBILE_APP_LOGIN),
      },
      permissions: {
        facilities: selectedFacilities,
        regions: user.regions,
        role: user.roles ? user.roles[0] : {
          name: {role_name: "user", enabled: false},
          description: "User"
        },
      }
    }
  }
}

const formatDate = (date: Date) => {
  return `${date.getFullYear()}-${(date.getMonth()+1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth()+1)}-${date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate()}`
}

export const generateReportUrl = (options: IReportRequestProps) : string => {
  const {requestUrl, reportId, organizationId, queryParams} = options

  let url = requestUrl.replace("{org_id}", organizationId.toString())
  if(reportId) url += '/file/' + reportId
  if(!queryParams) return url
  const entries = Object.entries(queryParams)
  if(entries.length > 0) {
    url += '?'
    entries.forEach(([key, value], index) => {
      if(index > 0) url += "&"
      if(value && value.value !== undefined) {
        switch (value.type) {
          case QueryTypes.Object:
            if(value.query && value.value.id) url += value.query + "=" + value.value.id
            break
          case QueryTypes.String:
            if(value.query) url += value.query + "=" + value.value
            break
          case QueryTypes.Array:
            if (value.query && Array.isArray(value.value)) {
              if (value.query == 'facility_ids') {
                const facilities = value.value
                  ?.map((item) => {
                    return item.facilities.map((i) => {
                      return { id: i.id, name: i.name }
                    })
                  })
                  .flat(1)
                facilities.forEach(v => url += value.query + "=" + v.id + "&")
              } else {
                value.value.filter(value => value.isSelected).forEach(v => url += value.query + "=" + v.id + "&")
              }
            }
            break
          case QueryTypes.Date:
            if(value.value.start) url += "date_start=" + formatDate(value.value.start)
            if(value.value.start && value.value.end) url += "&"
            if(value.value.end) url += "date_end=" + formatDate(value.value.end)
            if(value.value.isQuarterly !== undefined) url += "&is_quarterly=" + value.value.isQuarterly
            break
        }
      }
    })
  }
  url = url.replace(/&+/g, "&")
  url = url.replace(/&$/g, "")
  return url
}

function formatSort(sort: {
  property: string
  direction: SortDirection
}): string {
  return `${sort.direction === SortDirection.Descending ? "-" : ""}${sort.property}`
}

export const generateFilterUrl = (requestUrl: string, params?: {id: string, value: string}[], query?: RequestFilter | UserFilter | ScheduleFilter | ResidentFilter | RiskScoreChartFilter | WoundsFilter | WoundCareAction) : string => {
  let url = requestUrl
  params?.forEach((param) => {
    url = url.replace(param.id, param.value)
  })
  if(!query) return url
  url += '?'
  if(query.active != undefined) url += "active=" + query.active.toString() + "&"
  if(query.enabled != undefined) url += "enabled=" + query.enabled.toString() + "&"
  if(query.search) url += "q=" + query.search.trimStart().trimEnd() + "&"
  if(query.facilitySearch) url += "facility_view_q=" + query.facilitySearch.trimStart().trimEnd() + "&"
  if(query.page) url += "page=" + query.page + "&"
  if(query.size) url += "size=" + query.size + "&"
  if(query.sort) url += "sort=" + formatSort(query.sort) + "&"
  if("patient_id" in query && query.patient_id) url += "patient_id=" + query.patient_id + "&"
  if("roles" in query && query.roles) query.roles.forEach(cap => url += "roles=" + cap + "&")
  if("facilityIds" in query && query.facilityIds) query.facilityIds.forEach(id => url += "facility_ids=" + id + "&")
  if("regions" in query && query.regions) query.regions.forEach(id => url += "region_ids=" + id + "&")
  if("capabilities" in query && query.capabilities) query.capabilities.forEach(cap => url +=  "capabilities=" + cap + "&")
  if("file_format" in query && query.file_format) query.file_format.forEach(id => url += "file_format=" + id + "&")
  if("schedule_type" in query && query.schedule_type) query.schedule_type.forEach(id => url += "schedule_type=" + id + "&")
  if("report_type" in query && query.report_type) query.report_type.forEach(id => url += "report_type=" + id + "&")
  if("categories" in query && query.categories) query.categories.forEach(id => url +=  "categories=" + id + "&")
  if("status" in query && query.status) query.status.forEach(id => url +=  "status=" + id + "&")
  if("payers" in query && query.payers) query.payers.forEach(id => url += "payers=" + id + "&")
  if("physicians" in query && query.physicians) query.physicians.forEach(id => url += "physicians=" + id + "&")
  if("facility_states" in query && query.facility_states) query.facility_states.forEach(id => url += "facility_states=" + id + "&")
  if("order_statuses" in query && query.order_statuses) query.order_statuses.forEach(id => url += "order_statuses=" + id + "&")
  if("order_ids" in query && query.order_ids) query.order_ids.forEach(id => url += "order_ids=" + id + "&")
  if("order_types" in query && query.order_types) query.order_types.forEach(id => url += "order_types=" + id + "&")
  if("hidden_columns" in query && query.hidden_columns) query.hidden_columns.forEach(id => url += "hidden_columns=" + id + "&")
  if ('quality_measure' in query && query.quality_measure) {
    if (Array.isArray(query.quality_measure))
      query.quality_measure.forEach(
        (id) => (url += 'quality_measure=' + id + '&')
      )
    else url += 'quality_measure=' + query.quality_measure + '&'
  }
  if("scheduled_by_id" in query && query.scheduled_by_id) query.scheduled_by_id.forEach(id => url += "scheduled_by_id=" + id + "&")
  if("role_category" in query && query.role_category) url += "role_category=" + query.role_category + "&"
  if("date_start" in query && query.date_start) url += "date_start=" + query.date_start + "&"
  if("date_end" in query && query.date_end) url += "date_end=" + query.date_end + "&"
  if("special_filter" in query && query.special_filter) url += "special_filter=" + query.special_filter + "&"
  if("actions" in query && query.actions) url += "actions=" + query.actions + "&"
  if("group_id" in query && query.group_id) url += "group_id=" + query.group_id + "&"
  if("show_all" in query && query.show_all) url += "show_all=" + query.show_all + "&"
  url = url.replaceAll(/\&+/g, "&")
  url = url.replace(/\&$/g, "")
  return url
}
