import { Range } from '../components/DatePicker/datePicker'
import { DateRange } from '../views/Reports/reports'
import { OrgProductFeature } from './user-types'

export enum ReportType {
  DAILY_RISK_REPORT = "daily_risk_report",
  CROSS_FACILITY_RISK_REPORT = "cross_facility_risk_report",
  PREDICTION_HIT_MISS_REPORT = "prediction_hit_miss_report",
  OPEN_RATES = "open_rates",
  PREDICTION_PERFORMANCE_REPORT = "prediction_performance_report",
  HOSPITALIZATION_REPORT = "hospitalization_report",
  RESIDENT_FOLLOWUP = "resident_followup",
  RESIDENT_ABNORMAL_VITALS = "resident_abnormal_vitals",
  PATIENT_DAYS_REPORT = "patient_days_report"
}

export enum FilterType {
  MULTI_OPTION_SELECT = "multi_option_select",
  OPTION_SELECT_WITH_ITEMS = "option_select_items",
  OPTION_SELECT_WITH_REQUEST = "option_select_request",
  FACILITY_SELECT = "facility_select",
  DATE_PICKER = "date_picker",
  DROPDOWN = "dropdown",
  RADIO_SELECT = "radio_select"
}

export enum FormatTypes {
  FILE_TABLE = "file_table",
  PERIODIC_TABLE = "period_table",
  BASIC_TABLE = "basic_table",
}

// INTERFACES AND DEFAULT VALUES

export namespace SaivaReport {
  export namespace Filter {
    export type FetchFunction = (organizationId, controller: AbortController) => Promise<OptionItem[] | ParentOptionItem[]>
    export type MultiFetchFunction = (organizationId, controller: AbortController) => Promise<{[key: string]: SaivaReport.Filter.OptionItem[]} | undefined>

    export interface ParentOptionItem {
      id: any,
      label: string,
      children: OptionItem[]
    }

    export interface OptionItem {
      id: any,
      label: string,
      isSelected: boolean
    }

    export interface FacilitySelect {
      type: FilterType.FACILITY_SELECT,
      id: string,
      label: string,
      queryParam: string,
      required?: boolean
      manageButtonText?: string
    }

    export interface DatePicker {
      type: FilterType.DATE_PICKER,
      ranges: Range[],
      customDefaultRange?: LocalStorageDateItem
    }

    export interface Dropdown {
      type: FilterType.DROPDOWN,
      id: string,
      label: string
      queryParam: string,
      items: {label: string, value: any}[]
      popupLabel?: string,
      defaultOption?: any,
    }

    export interface OptionSelectBase {
      id: string,
      label: string,
      queryParam: string,
      manageButtonText?: string,
      noOptionsMessage?: string,
      color?: string,
      required?: boolean
    }

    export interface Parent {
      value: string,
      label: string,
      id: number
    }

    export interface OptionSelectWithItems extends OptionSelectBase {
      type: FilterType.OPTION_SELECT_WITH_ITEMS,
      items: OptionItem[]
    }

    export interface RadioSelect extends OptionSelectBase {
      type: FilterType.RADIO_SELECT,
      default_option: string,
      items: OptionItem[]
    }

    export interface OptionSelectWithRequest extends OptionSelectBase {
      type: FilterType.OPTION_SELECT_WITH_REQUEST,
      fetchData: FetchFunction
    }

    export interface MultiOptionSelect {
      type: FilterType.MULTI_OPTION_SELECT,
      fetchData: MultiFetchFunction
      optionSelects: OptionSelectWithItems[]
    }

    export interface ReportFilter {
      reportCategory?: string[] | undefined,
      qualityMeasure?: OrgProductFeature[] | undefined,
      search: string
    }
  }

  export type Filters =
    Filter.FacilitySelect |
    Filter.DatePicker |
    Filter.Dropdown |
    Filter.OptionSelectWithItems |
    Filter.OptionSelectWithRequest |
    Filter.MultiOptionSelect |
    Filter.RadioSelect

  export namespace Data {
    export interface TableStructure {
      flag?: string
      format: FormatTypes.PERIODIC_TABLE | FormatTypes.BASIC_TABLE,
      columns: {
          label: string,
          id: string,
          type: "number" | "string" | "percentage" | "date" | "boolean" | "array" | "url",
          sortable?: boolean
        }[],
      cumulative_columns?: TableStructure["columns"]
    }

    export interface TableStructureWithoutColumns {
      format: FormatTypes.FILE_TABLE
    }

    export namespace Stats {
      export interface Item {
        type?: "number" | "percentage"
        label: string
        data: {value: string, increase?: boolean}
        dateRange: string
      }

      export interface Group {
        type: "group",
        label: string,
        items: Item[]
      }
    }

    export type Stats = Stats.Item | Stats.Group

    export type Charts = {
      title: string,
      datasets: {
        label: string,
        data: number[]
      }[]
    }[]

    export interface FilterItem {
      id: string,
      items: SaivaReport.Filter.OptionItem[]
    }

    export type Filter = FilterItem[]

  }

  export type Data = Data.TableStructure | Data.TableStructureWithoutColumns

  export interface Options {
    pdfDownload: {url: string},
    csvDownload: {url: string},
    pdfDownloadCumulative?: {url: string},
    csvDownloadCumulative?: {url: string},
    schedule: boolean
    exportOptions: boolean
  }

  export interface CatalogItem {
    label: string,
    id: string,
    description: string,
    reports: {label: string, id: string, description: string, quality_measure: OrgProductFeature}[],
    quality_measure: OrgProductFeature
  }

  export interface Catalog {
    items: StructureItem[]
  }

  export interface Categories {
    categories: {
      label: string,
      id: string,
      color: {color: string, bg: string},
      report_types: CatalogItem[]
    }[] | undefined
  }

  export interface StructureItem {
    id: string,
    url: string,
    label: string,
    description: string,
    quality_measure: OrgProductFeature,
    allReportsInCategory: {label: string, id: string}[],
    filters: Filters[],
    dataType: Data,
    reportOptions?: Options,
    flag?: string
    charts?: Data.Charts
  }

  export type Structure = StructureItem[]

}

export type LocalStorageDateItem = {day?: DateRange, daily?: DateRange, monthly?: DateRange, quarterly?: DateRange, defaultRange?: Range} | undefined


export interface IGeneralReportData<T> {
  format: FormatTypes
  data: T | undefined
  cumulative_data?: T | undefined
  filters?: SaivaReport.Data.Filter
  stats?: SaivaReport.Data.Stats[],
  charts?: SaivaReport.Data.Charts,
}


// STATS

// RESPONSES

// PARSE FUNCTIONS

