import mixpanel from 'mixpanel-browser';
import { MIXPANEL_ACTIVE, MIXPANEL_TOKEN } from './constants'
import * as Sentry from '@sentry/react'
import { IDateRange } from '../components/DatePicker/datePicker'
import { UserFilter } from '../services/user-service'
import { SaivaUser } from '../types/user-types'
import { ScheduleFilter } from 'types/schedule-reports';

const MIXPANEL_DEBUG_MODE = false

interface ReportOptions {
  reportType: string
}

interface DefaultValue {
  org: SaivaUser.OrgItem,
  browser: any,
  email: string,
  title: string
}

interface SettingsOpts {
  organisation: string | undefined,
  user: string,
}

const capitalize = (string) => {
  if (typeof string !== 'string') return null
  return string.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
}

const isValidFilter = (filter: UserFilter | undefined) : boolean => {
  if(!filter) return false
  return Boolean((filter.capabilities && filter.capabilities?.length > 0) || (filter.roles && filter.roles?.length > 0) || (filter.facilityIds && filter.facilityIds?.length > 0))
}

class Mixpanel {

  public reportType: string | undefined = undefined
  public defaultValue: DefaultValue | undefined = undefined

  constructor() {
    mixpanel.init(MIXPANEL_TOKEN, {debug: MIXPANEL_DEBUG_MODE})
  }

  // UTILS

  validate(func : () => any, text?: string ) {
    try {
      if(mixpanel && mixpanel.__loaded && MIXPANEL_ACTIVE) func()
    } catch (e) {
      Sentry.captureException(`An exception occurred in mixpanel class. ${text ? text : ""} ${e}`)
    }
  }

  setDefault (value : DefaultValue) {
    const partner_id = value.org.partner_id
    this.validate(() => {
      const newValue = {
        org_name: value.org.name,
        org_id: value.org.id,
        partner_id: partner_id,
        partner_name: capitalize(partner_id),
        browser_name: value?.browser?.name,
        browser_version: value?.browser?.version,
        browser_type: value?.browser?.type,
        browser_os: value?.browser?.os,
        email: value.email,
        title: value.title,
        role: value.org.role,
        platform: 'web'
      }
      this.defaultValue = value
      mixpanel.register(newValue)
    }, "set default")
  }

  setOrganization (org: SaivaUser.OrgItem) {
    this.validate(() => {
      const newValue = {
        org_name: org.name,
        org_id: org.id,
        partner_id: org.partner_id,
        partner_name: capitalize(org.partner_id),
        browser_name: this.defaultValue?.browser?.name,
        browser_version: this.defaultValue?.browser?.version,
        browser_type: this.defaultValue?.browser?.type,
        browser_os: this.defaultValue?.browser?.os,
        email: this.defaultValue?.email,
        title: this.defaultValue?.title,
        role: this.defaultValue?.org.role
      }
      mixpanel.register(newValue)
    }, "set default")
  }

  // COMMON

  login (email: string, type: string) {
    this.validate(() => {
      const did = mixpanel.get_distinct_id();
      mixpanel.alias(email, did)
      mixpanel.identify(email);
      mixpanel.people.set({
        "$name": email.split('@')[0],
        "$email": email
      });
      mixpanel.track("Login" + type)
    }, "login")
  }

  logout () {
    this.validate(async () => {
      await mixpanel.track("Logout")
      mixpanel.reset()
    }, "logout")
  }

  isActive (email: string) {
    this.validate(() => {
      mixpanel.identify(email);

      mixpanel.people.set({
        "$name": email.split('@')[0],
        "$email": email
      });
    }, "is active")
  }

  navigationChange (type: string) {
    this.validate(() => {
      const value = type.replace(" ", "")
      mixpanel.track("Navigation" + value)
    })
  }

  organizationChange (oldOrg: SaivaUser.OrgItem, newOrg: SaivaUser.OrgItem) {
    this.validate(() => {
      this.setOrganization(newOrg)
      mixpanel.track("OrganizationChange", {
        old_organization: oldOrg.name,
        new_organization: newOrg.name
      })
    })
  }

  // USERS

  usersFilter (filter: UserFilter) {
    this.validate(() => {
      if (filter.search) mixpanel.track("UsersSearch", { "search_value": filter })
      if (isValidFilter(filter)) mixpanel.track("UsersFilter", { "filter_value": filter })
    })
  }

  usersSortTable (sortedProperty: any, direction: any) {
    this.validate(() => {
      mixpanel.track("UsersSortTable", { "sorted_by": sortedProperty, "direction": direction })
    })
  }

  usersInvitation (invitation: any) {
    this.validate(() => {
      mixpanel.track("UsersInvitation", {...invitation})
    })
  }

  usersEdit (user: any) {
    this.validate(() => {
      mixpanel.track("UsersEdit", {...user})
    })
  }

  usersDisable (user: any) {
    this.validate(() => {
      mixpanel.track("usersDisable", {...user})
    })
  }

  usersTabChange (tab: string) {
    this.validate(() => {
      const value = tab.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
      mixpanel.track("UsersTab" + value)
    })
  }

  // FACILITIES 

  facilityEdit (facility: any) {
    this.validate(() => {
      mixpanel.track("FacilityEdit", {...facility})
    })
  }

  // REPORTS

  reportsNavigationChange(name: string, type: string, filters?: any) {
    const value = name.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
    const eventName = "ReportsNavigation" + value;
  
    this.validate(() => {
      this.reportType = type
      mixpanel.track(eventName, {...(filters || {}), report_type: type})
    })
  }

  reportsDateSelect (date: IDateRange, options?: ReportOptions, filters?: any) {
    this.validate(() => {
      mixpanel.track("ReportsDateSelect", {
        ...filters,
        report_type: options?.reportType,
        date_range_start: date.start?.toUTCString(),
        date_range_end: date.end?.toUTCString(),
        is_quarterly: date.isQuarterly
      })
    })
  }

  facilitySelect (facilities: string[], facilities_ids: string[], regions: string[], regions_ids: string[], app: string, options?: ReportOptions) {
    this.validate(() => {
      if(facilities.length === 0) return
      mixpanel.track(`${app === "medical_supply" ? "OrderCapture" : "Reports"}FacilitySelect`, {
        report_type: options?.reportType,
        facilities: facilities,
        facilities_ids: facilities_ids,
        regions_ids: regions_ids,
        regions: regions
      })
    })
  }

  reportsTabSelect (reportType: string, tab: string, filters?: any) {
    this.validate(() => {
      const newTab = tab.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
      const eventName = "ReportsTab" + newTab

      mixpanel.track(eventName, {
        ...(filters || {}),
        tab: newTab,
        report_type: reportType,
      })
    })
  }

  reportsFileDownload (reportType: string, url: string, type: string | undefined, filters?:any, view?: string) {
    this.validate(() => {
      mixpanel.track("ReportsFileDownload", {
        ...filters,
        file_url: url,
        file_type: type,
        report_type: reportType,
        view: view
      })
    })
  }

  reportOpenFromWebPortal (reportType: string, filters?: any) {
    this.validate(() => {
      mixpanel.track("ReportsOpenFromWebPortal", {
        ...filters,
        report_type: reportType,
      })
    })
  }


  settingsEventHandler(opts: SettingsOpts, trackName: string) {
    const { organisation, user } = opts;
    this.validate(() => {
      mixpanel.track(trackName, {
        organisation: organisation,
        user: user,
      })
    })
  }

  integrationEventHandler(opts, trackName) {
    const { integration_type,
      organisation, user, facilities, facilities_ids, regions, regions_ids, reports, is_enabled } = opts;
    this.validate(() => {
      mixpanel.track(trackName, {
        integration_type: integration_type,
        organisation: organisation,
        user: user,
        facilities: facilities,
        facilities_ids: facilities_ids,
        regions: regions,
        regions_ids: regions_ids,
        reports: reports,
        is_enabled: is_enabled,
      })
    })
  }

  reportWidgetStatusSelect (status: string,  report_type: string, filters?: any) {
    this.validate(() => {
      mixpanel.track("ReportWidgetStatusSelect", {...filters, status: status,  report_type: report_type})
    })
  }

  // SCHEDULES 

  scheduleCreate (schedule: any) {
    this.validate(() => {
      mixpanel.track("ScheduleCreate", {...schedule})
    })
  }

  scheduleEdit (schedule: any) {
    this.validate(() => {
      mixpanel.track("ScheduleEdit", {...schedule})
    })
  }

  scheduleDelete (schedule: any) {
    this.validate(() => {
      mixpanel.track("ScheduleDelete", {...schedule})
    })
  }

  scheduleViewAll () {
    this.validate(() => {
      mixpanel.track("ScheduleViewAll")
    })
  }

  scheduleFilter (filter: ScheduleFilter) {
    this.validate(() => {
      mixpanel.track("ScheduleFilter", {...filter})
    })
  }
  // RESIDENTS

  openResident (resident: any, quality_measure: string) {
    this.validate(() => {
      mixpanel.track("OpenResident", {...resident, quality_measure: quality_measure})
    })
  }

  switchResidentTab (tab: string) {
    this.validate(() => {
      mixpanel.track("switchResidentTab", {tab: tab})
    })
  }

  shareResident (resident: any, quality_measure: string) {
    this.validate(() => {
      mixpanel.track("ShareResident", {...resident, quality_measure: quality_measure})
    })
  }

  // QM

  switchQM (qm: string, page: string, report?: string) {
    const value = {
      quality_measure: qm,
      page: page
    }
    this.validate(() => {
      mixpanel.track("SwitchQM" + value.page, {...value, report: report})
    })
  }

  // orderCapture

  orderCaptureNavigationChange () {
    this.validate(() => {
      mixpanel.track("NavigationOrderCapture")
    })
  }

  orderCaptureSelectDates (dates: any) {
    this.validate(() => {
      mixpanel.track("OrderCaptureDateSelect", {...dates})
    })
  }

  orderCaptureSortTable (properties: any) {
    this.validate(() => {
      mixpanel.track("OrderCaptureSortTable", { sorted_by: properties.sortedProperty, direction: properties.direction })
    })
  }

  orderCaptureFilter (filterId: string, filterValue: any) {
    this.validate(() => {
      mixpanel.track("OrderCaptureFilter", {
        filter_id: filterId,
        filter_value: filterValue
      })
    })
  }

  orderCaptureFileDownload (url: string | undefined, type: string | undefined, date?: any, facilities?: string[], regions?: string[]) {
    this.validate(() => {
      mixpanel.track("OrderCaptureFileDownload", {
        file_url: url,
        file_type: type,
        date_range_start: date.date_start,
        date_range_end: date.date_end,
        selected_facilities: facilities,
        selected_regions: regions
      })
    })
  }

  orderCaptureAction (action: {action: string}, orderIds: {order_ids: number[]}, bulk: boolean) {
    this.validate(() => {
      mixpanel.track("OrderCaptureAction", {action: action.action, orderIds: orderIds.order_ids, bulk: bulk})
    })
  }

  orderCaptureWidgetStatusSelect (status: string) {
    this.validate(() => {
      mixpanel.track("OrderCaptureWidgetStatusSelect", {status: status})
    })
  }
}

export const mixpanelInstance = new Mixpanel()
