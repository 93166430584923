import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Tooltip } from 'antd'
import Gradient from 'assets/icons/Gradient.gif'
import moment from 'moment'

import {ReactComponent as DownloadIcon} from '../../../assets/icons/download.svg'

interface IFileTableDataItem {
  id: string,
  name: string,
  region_name: string,
  reports: {
    date: string,
    id: string
  }[]
}


export type IFileTableData = IFileTableDataItem[]

interface ComponentProps {
  data: IFileTableData,
  dateRange?: {start: Date, end: Date}
  onReportClick: (reportId: string, setFileLoading: (value: any) => void) => void
}

const formatDate = (value: string) => {
  const date = value.split("-")
  return date[1] + "/" + date[2] + "/" + date[0]
}

const getNumberOfDays = (dateRange: { start: Date; end: Date } | undefined) => {
  if (!dateRange) return 0
  return (
    Math.abs(
      moment(dateRange.start, 'YYYY-MM-DD')
        .startOf('day')
        .diff(moment(dateRange.end, 'YYYY-MM-DD').startOf('day'), 'days')
    ) + 1
  )
}

const FileItem = ({report, onReportClick, noFormat = false}) => {

  const [fileLoading, setFileLoading] = useState<boolean>(false)

  return (
    <div
      className={`item`}
    >
      <div className={`${noFormat ? 'noHover' : ''}`} onMouseUp={() => onReportClick(report, setFileLoading)}>
        {!noFormat ? fileLoading ? <img className='me-2' style={{marginRight: 8}} src={Gradient} width='20px' height='20px' alt={"loading gif"}/> : <DownloadIcon /> : null}
        {noFormat ? report.date : formatDate(report.date)}
      </div>
    </div>
  )
}

const FileTable = ({data, onReportClick, dateRange}: ComponentProps) => {

  const rows = useRef<HTMLDivElement>(null)
  const columns = useRef<HTMLDivElement>(null)
  const [moving, setMoving] = useState(false)

  const [headerWidth, setHeaderWidth] = useState<number>(0)
  const [regionsWidth, setRegionsWidth] = useState<number>(0)

  const isInReports = (reports: {id: string, date: string}[], day: number) => {
    if(!dateRange) return undefined
    const date = new Date(dateRange.start)
    date.setDate(date.getDate() + day)
    const zeroAdd = (string) => {
      return string.length === 1 ? "0" + string : string
    }
    const string = date.getFullYear() + "-" + zeroAdd((date.getMonth() + 1).toString()) + "-" + zeroAdd(date.getDate().toString())
    return reports.find((report) => {
      return report.date === string
    })
  }

  const handleMouseDown = () => {
    rows.current?.addEventListener("mousemove", handleMouseMove)
  }

  const handleMouseUp = () => {
    rows.current?.removeEventListener("mousemove",  handleMouseMove)
    setMoving(false)
  }

  const handleMouseMove = (e) => {
    if(rows.current && columns.current) {
      setMoving(true)
      rows.current.scrollTop -= e.movementY;
      columns.current.scrollLeft -= e.movementX
    }
  }

  useEffect(() => {
    const sidebar = document.getElementById("file-table-sidebar")
    const regionsSidebar = document.getElementById("regions-table-sidebar")
    if(sidebar && regionsSidebar) {
      setHeaderWidth(sidebar.clientWidth)
      setRegionsWidth(regionsSidebar.clientWidth)
    }
  }, [data])

  useEffect(() => {
    const item = rows.current
    item?.addEventListener("mousedown", handleMouseDown)
    window.addEventListener("mouseup", handleMouseUp)

    return () => {
      item?.removeEventListener("mousedown", handleMouseDown)
      window.removeEventListener("mouseup", handleMouseUp)
    }
  }, [])

  if(!data || data.length === 0) {
    return <div className={'center'}>
      No Data to Display
    </div>
  }

  return (
    <div className={"fileTableWrapper"}>
      <div className={"header d-none d-lg-flex"}>
        <div style={{width: headerWidth + "px"}}>Facilities</div>
        <div style={{width: regionsWidth + "px"}}>Regions</div>
        <div>Date</div>
      </div>
      <div ref={rows} className={"contentWrapper noselect"}>
        <div id={"file-table-sidebar"} className={`fileSidebar`}>
          {data?.map(facility => {
            return <div key={facility.id} className={"itemWrapper"}><div className={"item elipsis"}>
              <Tooltip 
                arrow={false}
                overlayInnerStyle={{
                  background: '#112443',
                  textAlign: 'left'
                }}
                title={facility.name}
              >
                {facility.name}
              </Tooltip>
            </div></div>
          })}
        </div>
        <div id={"regions-table-sidebar"} className={`fileSidebar`}>
          {data?.map(facility => {
            return <div key={facility.id} className={"itemWrapper"}><div className={"item elipsis"}>
              <Tooltip 
                arrow={false}
                overlayInnerStyle={{
                  background: '#112443',
                  textAlign: 'left'
                }}
                title={facility.region_name}
              >
              {facility.region_name}
              </Tooltip>
            </div>
          </div>
          })}
        </div>
        <div className={"shadows"}>
          {data?.map(facility => {
            return (
              <div key={facility.id} className={'row'}/>
            )
          })}
        </div>
        <div className={"content"} style={{paddingLeft: (Number(headerWidth) + Number(regionsWidth)) + "px", marginLeft: "-" + (Number(headerWidth) + Number(regionsWidth)) + "px"}}>
          <div className={"rowsWrapper"} ref={columns}>
            {data?.map(facility => {
              const numOfDays = getNumberOfDays(dateRange)
              return (
                <div key={facility.id} className={"itemsRow"} style={{minWidth: numOfDays  * 127}}>
                  {Array.from({length: numOfDays}, (_, i) => i)?.map((day) => {
                    const report = isInReports(facility.reports, day)
                    if(report) return <Fragment key={day}><FileItem report={report} onReportClick={(reportId, setFileLoading) => {if(!moving) onReportClick(reportId, setFileLoading)}}/></Fragment>
                    else return <Fragment key={day}><FileItem report={{date: "N/A"}} noFormat onReportClick={() => {}} /></Fragment>
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )

}

export default FileTable
