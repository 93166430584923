import { detect } from 'detect-browser'
import { showErrorToast, showSuccessToast } from '../utils'
import { handleError } from './errorHandler'
import { Schedules } from 'types/schedule-reports'
import { colors } from 'react-select/dist/declarations/src/theme'
import { OrgProductFeature } from 'types/user-types'

export const getBrowserInfo = detect()

export const compareArrays = (a, b) => {
  return a.sort().toString() === b.sort().toString()
}

export const isMobile = (): boolean => {
  return Boolean(
    navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
  )
}

export const smScreen = () => {
  return window.innerWidth < 890
}

export const copyToClipboard = async (
  value: string | undefined,
  label?: string
) => {
  if (value) {
    try {
      await navigator.clipboard.writeText(value)
      if (label) showSuccessToast(label + ' successfully copied to clipboard!')
      else showSuccessToast('Successfully copied to clipboard!')
    } catch (e) {
      handleError({ id: 'CopyToClipboard', error: e })
      showErrorToast('An error occurred when copying to clipboard')
    }
  }
}

export const getReportId = (reportType: string) => {
  if (
    reportType == 'cross_facility_risk_report' ||
    reportType == 'cross_facility_report'
  )
    return Schedules.ReportTypes.CROSS_FACILITY_REPORT
  if (
    reportType == 'prediction_hit_miss_report' ||
    reportType == 'hit_miss_report'
  )
    return Schedules.ReportTypes.HIT_MISS_REPORT
  // if (reportType == 'open_rates' || reportType == 'open_rate_report')
  //   return Schedules.ReportTypes.OPEN_RATE_REPORT
  if (
    reportType == 'prediction_performance_report' ||
    reportType == 'predictions_performance_report'
  )
    return Schedules.ReportTypes.PREDICTIONS_PERFORMANCE_REPORT
  if (
    reportType == 'hospitalization_report' ||
    reportType == 'unplanned_transfers'
  )
    return Schedules.ReportTypes.UNPLANNED_TRANSFERS
  if (
    reportType == 'patient_days_report'
  )
    return Schedules.ReportTypes.PATIENT_DAYS_REPORT
  if (reportType == 'resident_followup' || reportType == 'visits_report')
    return Schedules.ReportTypes.VISITS_REPORT
  if (reportType == 'resident_abnormal_vitals' || reportType == 'vitals_report')
    return Schedules.ReportTypes.VITALS_REPORT
  else return Schedules.ReportTypes.DEFAULT
}

export const getReportLabel = (
  reportType: Schedules.ReportTypes | undefined
) => {
  if (reportType == Schedules.ReportTypes.CROSS_FACILITY_REPORT)
    return {
      label: 'Cross-Facility Risk Report',
      color: '#a03afd',
      bg: '#ecd8ff'
    }
  if (reportType == Schedules.ReportTypes.HIT_MISS_REPORT)
    return {
      label: 'Prediction Hit/Miss Report',
      color: '#4070dc',
      bg: '#d9e2f8'
    }
  // if (reportType == Schedules.ReportTypes.OPEN_RATE_REPORT)
  //   return {
  //     label: 'Open Rates',
  //     color: '#60c2ca',
  //     bg: 'rgba(96, 194, 202, 0.2)'
  //   }
  if (reportType == Schedules.ReportTypes.PREDICTIONS_PERFORMANCE_REPORT)
    return {
      label: 'Prediction Performance Report',
      color: '#4070dc',
      bg: '#d9e2f8'
    }
  if (reportType == Schedules.ReportTypes.UNPLANNED_TRANSFERS)
    return {
      label: 'Unplanned Transfers Report',
      color: '#a03afd',
      bg: '#ecd8ff'
    }
  if (reportType == Schedules.ReportTypes.VISITS_REPORT)
    return {
      label: 'Resident Followup',
      color: '#c9c13e',
      bg: 'rgba(201, 193, 62, 0.2)'
    }
  if (reportType == Schedules.ReportTypes.VITALS_REPORT)
    return {
      label: 'Resident Abnormal Vitals',
      color: '#c9c13e',
      bg: 'rgba(201, 193, 62, 0.2)'
    }
  if (reportType == Schedules.ReportTypes.PATIENT_DAYS_REPORT)
    return {
      label: 'Patient Days Report',
      color: '#a03afd',
      bg: '#ecd8ff'
    }
  if (reportType == Schedules.ReportTypes.DEFAULT)
    return {
      label: 'Default Report',
      color: '#a03afd',
      bg: 'rgba(201, 193, 62, 0.2)'
    }
  else return null
}

export const getFeatureLabel = (
  reportType: OrgProductFeature | undefined | string
) => {
  if (reportType == OrgProductFeature.MODEL_FALLS)
    return {
      label: 'Falls',
      id: 'model_fall'
    }
  if (reportType == OrgProductFeature.MODEL_UPT)
    return {
      label: 'UPT',
      id: 'model_upt'
    }
  if (reportType == OrgProductFeature.MODEL_RTH)
    return {
      label: 'RTH',
      id: 'model_rth'
    }
  if (reportType == OrgProductFeature.MODEL_WOUNDS)
    return {
      label: 'Wounds',
      id: 'model_wound'
    }
  else return { label: '', id: '' }
}

export const getCategoryLabel = (reportCategory: string) => {
  if (reportCategory == 'prediction_performance')
    return 'Prediction Performance'
  if (reportCategory == 'adverse_events') return 'Adverse Events'
  if (reportCategory == 'resident_insight') return 'Resident Insight'
  if (reportCategory == 'adoption') return 'Adoption'
  else return null
}
