import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './SFTPModal.module.scss'
import MultiOptionSelect, {
  SelectOption
} from '../../../../components/Form/MultiOptionSelect'
import {
  IntegrationType,
  SaivaIntegration,
  StatusType
} from '../../../../types/integration-types'
import TextArea from '../../../../components/Form/TextArea'
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg'
import { SaivaFacility } from '../../../../types/facility-types'
import FacilityService from '../../../../services/facility-service'
import { UserContext, useUserContext } from '../../../../context/UserContext'
import IntegrationModalWrapper, {
  useIntegrationStatus
} from '../IntegrationModal'
import { IntegrationRequest } from '../../../../services/integration-service'
import { useFormContext } from '../../../../context/FormProvider'
import { copyToClipboard } from '../../../../utils/helper'
import { ValidatorTypes } from '../../../../utils/validation'
import { mixpanelInstance } from 'utils/mixpanel'

import SaivaCustomSelect from 'components/SaivaForm/SaivaMultiSelect/SaivaCustomSelect'
import SaivaSelect from 'components/SaivaForm/SaivaSelect/saivaSelect'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import RegionService from 'services/region-service'
import { getFeatureLabel } from 'utils/helper'

const parseFacilityIdsToOptions = (
  facilities: number[] | undefined,
  allFacilities: SelectOption[]
): SelectOption[] => {
  const options: SelectOption[] = []
  facilities?.forEach((facility) => {
    const f = allFacilities.find((i) => i.value === facility)
    if (f) options.push(f)
  })
  return options
}

const parseReportTypesToOptions = (
  reports: string[] | undefined,
  allReports: SelectOption[]
): SelectOption[] => {
  const options: SelectOption[] = []
  reports?.forEach((report) => {
    const r = allReports.find((i) => i.value === report)
    if (r) options.push(r)
  })
  return options
}

const parseFacilitiesToOptions = (
  facilities: SaivaFacility.Item[]
): SelectOption[] => {
  return facilities.map((f) => {
    return {
      label: f.name,
      value: f.id,
      parent: f.region_id,
      color: '#799BE6'
    }
  })
}

interface SFTPModalProps {
  item: SaivaIntegration.SFTPItem
  updateItem: (item: Partial<SaivaIntegration.SFTPItem>) => void
  open: boolean
  handleClose: () => void
}

const RATIO_DATA: SelectOption[] = [
  { label: '5%', value: 0.05 },
  { label: '10%', value: 0.1 },
  { label: '15%', value: 0.15 },
  { label: '20%', value: 0.2 },
  { label: '25%', value: 0.25 },
  { label: '30%', value: 0.3 },
  { label: '35%', value: 0.35 },
  { label: '40%', value: 0.4 },
  { label: '45%', value: 0.45 },
  { label: '50%', value: 0.5 },
  { label: '55%', value: 0.55 },
  { label: '60%', value: 0.6 },
  { label: '65%', value: 0.65 },
  { label: '70%', value: 0.7 },
  { label: '75%', value: 0.75 },
  { label: '80%', value: 0.8 },
  { label: '85%', value: 0.85 },
  { label: '90%', value: 0.9 },
  { label: '100%', value: 1 }
]

function SFTPModal(props: SFTPModalProps) {
  const { t } = useTranslation()
  const userContext = useUserContext()
  const formContext = useFormContext()
  const {errors, addValidationType, getValue} = useFormContext()
  const data = formContext.getValues<SaivaIntegration.SFTP.Data>()
  const { open, handleClose } = props
  const [allFacilities, setFacilities] = useState<SelectOption[]>([])
  const [regions, setRegions] = useState<any>([])

  const modelProductFeatures = userContext.currentOrg.productFeatures.filter(
    (item) => {
      return item.includes('model' || 'rth')
    }
  )
  const REPORT_TYPES: SelectOption[] = modelProductFeatures.map((i) => {
    return { label: getFeatureLabel(i)?.label, value: getFeatureLabel(i)?.id }
  })

  useEffect(() => {
    addValidationType('reportTypes', [{required: true, error: "At least one Report Type is required"}])
    addValidationType('facilityIds', [{required: true, error: "At least one facility is required"}])
    fetchFacilities()
  }, [])

  useEffect(() => {
    fetchFacilities()
    fetchRegions()
    if (data && !data.riskListLengthRatio) {
      formContext.updateValue({ riskListLengthRatio: 0.15 })
    }
  }, [userContext.currentOrg.id])

  const item = useMemo(() => {
    return props.item
  }, [props.item])


  const { currentOrg, email } = userContext
  const filteredRegions = regions.filter((i) => {
    return i.children.map((f: any) => {
      return data?.facilityIds?.includes(f.value)
    }).includes(true)
  })
  const mixpanelEventsData = {
    integration_type: item?.id,
    organisation: currentOrg?.name,
    user: email,
    facilities: parseFacilityIdsToOptions(
      data?.facilityIds,
      allFacilities
    )?.map((e) => e.label),
    facilities_ids: parseFacilityIdsToOptions(
      data?.facilityIds,
      allFacilities
    )?.map((e) => e.value),
    regions: filteredRegions.map((i: any) => i.label),
    regions_ids:filteredRegions.map((i: any) => i.value),
    reports: data?.reportTypes,
    is_enabled: Boolean(data?.isEnabled),
  }

  const fetchFacilities = async () => {
    try {
      const facilities = await FacilityService.getAllFacilities(
        userContext.currentOrg.id
      )
      if (facilities) setFacilities(parseFacilitiesToOptions(facilities))
    } catch (e) {
      setFacilities([])
    }
  }

  const fetchRegions = async () => {
    try {
      const regions = await RegionService.getRegions(
        userContext.currentOrg.id
      )
      if (regions) setRegions(
        regions.map((i) => {
          return {
            value: i.id,
            label: i.name,
            children: i.facilities
              .map((f) => {
                return {value: f.id, label: f.name, parent: i.id}
              }
            )
          }}
        ))
    } catch (e) {
      setRegions([])
    }
  }

  function getUpdatePayload(): IntegrationRequest.Update<IntegrationType.SFTP_EXPORT> {
    const value: IntegrationRequest.SFTP.Update = {
      file_format: 'json',
      risk_list_length_ratio: 0.15,
      regions: []
    }
    if (data?.facilityIds) value.facility_ids = data.facilityIds
    if (data?.reportTypes) value.report_types = data.reportTypes
    if (data?.publicKeyHash) value.public_key = data.publicKeyHash
    if (data?.riskListLengthRatio)
      value.risk_list_length_ratio = Number(data?.riskListLengthRatio)
    value.is_enabled = Boolean(data?.isEnabled)

    mixpanelInstance.integrationEventHandler(
      mixpanelEventsData,
      'integrationUpdate'
    )
    return value
  }

  function getCreatePayload(): IntegrationRequest.Create<IntegrationType.SFTP_EXPORT> {
    const filteredRegions = regions.filter((i) => {
      return i.children.map((f: any) => {
        return data?.facilityIds?.includes(f.value)
      }).includes(true)
    })
    const message = {
      integration_type: item?.id,
      organisation: currentOrg?.name,
      user: email,
      facilities: parseFacilityIdsToOptions(
        data?.facilityIds,
        allFacilities
      )?.map((e) => e.label),
      facilities_ids: parseFacilityIdsToOptions(
        data?.facilityIds,
        allFacilities
      )?.map((e) => e.value),
      regions: filteredRegions.map((i: any) => i.label),
      regions_ids: filteredRegions.map((i: any) => i.value),
      reports: data?.reportTypes,
      is_enabled: Boolean(data?.isEnabled),
    }

    const value: IntegrationRequest.SFTP.Create = {
      facility_ids: [],
      report_types: [],
      file_format: 'json',
      risk_list_length_ratio: 0.15,
    }
    if (data?.facilityIds) value.facility_ids = data.facilityIds
    if (data?.reportTypes) value.report_types = data.reportTypes
    if (data?.publicKeyHash) value.public_key = data.publicKeyHash
    if (data?.riskListLengthRatio)
      value.risk_list_length_ratio = Number(data?.riskListLengthRatio)

    mixpanelInstance.integrationEventHandler(
      message,
      'integrationConnect'
    )

    return value
  }

  const { isConnected, isEnabled } = useIntegrationStatus({
    statusBase: item?.statusBase,
    enabled: data?.isEnabled
  })

  interface InputProps {
    value: string | undefined
    label: string
    placeholder?: string
  }

  const IntegrationInput = (props: InputProps) => {
    const { value, label } = props
    const inputRef = useRef<HTMLInputElement>(null)

    const handleClick = () => {
      if (inputRef.current) inputRef.current.focus()
    }

    return (
      <div className={styles.integrationInput} onClick={handleClick}>
        <label className={styles.title}>{label}</label>
        {/*<input ref={inputRef} defaultValue={props.value} />*/}
        <label className={styles.value}>{value}</label>
        <CopyIcon onClick={() => copyToClipboard(value, label)} />
      </div>
    )
  }

  const regexValidator: ValidatorTypes = {
    regex:
      /^(.*(ecdsa-sha2-nistp|rsa-sha2-|ssh-ed25519|ssh-rsa)(256|384|521|512)*[@A-Za-z.0-9]*|)\s[0-9A-Za-z+\/]+[=]{0,3} ([^@]+@[^@]+)$/,
    error: t('integrations.sftpModal.unsupportedSSH')
  }

  const ratioSelectValue = () => {
    const ratio = RATIO_DATA.find((e) => e.value == data?.riskListLengthRatio)
    return ratio ? ratio : RATIO_DATA.find((e) => e.value == 0.15)
  }

  const values = allFacilities.map((r) => {
    return {
      value: r.value,
      label: r.label,
      parent: r.parent
    }
  })

  return (
    <IntegrationModalWrapper<IntegrationType.SFTP_EXPORT>
      open={open}
      handleClose={handleClose}
      getUpdatePayload={getUpdatePayload}
      getCreatePayload={getCreatePayload}
      item={item}
      updateItem={props.updateItem}
      disconnectMessage={
        t('integrations.sftpModal.DisconnectMessage')
      }
      hasTest
      hasUpdate
      hasEnabled
      mixpanelEventsData={mixpanelEventsData}
    >
      <div className={styles.values}>
        <IntegrationInput label={'Hostname'} value={item.values?.hostname} />
        <IntegrationInput label={'Username'} value={item.values?.username} />
      </div>
      <Row gutter={[16, 16]} align="top" style={{marginTop: '20px'}}>
        <Col span={12}>
          <SaivaSelect
            value={data?.reportTypes}
            multiselect={false}
            icon={null}
            reset={() => {
              formContext.updateValue({ reportTypes: '' })
            }}
            label={t('integrations.sftpModal.form.label.qualityMeasure')}
            options={REPORT_TYPES}
            placeholder={t('integrations.sftpModal.form.placeholder.qualityMeasure')}
            required={true}
            errors={errors.get('reportTypes') ? {message: errors.get('reportTypes')} : undefined}
            onChange={(e) => {
              formContext.updateValue({ reportTypes: [e] })
            }}
          />
        </Col>
        <Col span={12}>
          <SaivaSelect
            value={data?.riskListLengthRatio}
            defaultValue={ratioSelectValue()}
            icon={null}
            reset={() => {
              formContext.updateValue({ riskListLengthRatio: '' })
            }}
            label={t('integrations.sftpModal.form.label.rankList')}
            options={RATIO_DATA}
            placeholder={t('integrations.sftpModal.form.placeholder.rankList')}
            required={true}
            errors={errors.get('riskListLengthRatio') ? {message: errors.get('riskListLengthRatio')} : undefined}
            onChange={(e) =>
              formContext.updateValue({ riskListLengthRatio: e })
            }
          />
        </Col>
        <Col span={12}>
          <SaivaCustomSelect
            value={data && data.facilityIds ? data.facilityIds : []}
            name="facilityIds"
            label={t('integrations.sftpModal.form.label.facilities')}
            reset={() => {
              formContext.updateValue({ facilityIds: [] })
            }}
            values={values}
            parents={regions}
            disabled={regions?.length === 0}
            required={true}
            errors={errors.get('facilityIds') ? {message: errors.get('facilityIds')} : undefined}
            onChange={(e) => {
              formContext.updateValue({ facilityIds: e })
            }}
          />
        </Col>
        <Col span={24}>
          <TextArea
            id={'publicKeyHash'}
            label={t('integrations.sftpModal.form.label.PublicKey')}
            validators={
              isConnected
                ? [regexValidator]
                : [{ required: true }, regexValidator]
            }
            placeholder={
              isConnected || item?.statusBase === StatusType.CONNECTING
                ? t('integrations.sftpModal.form.placeholder.isNotConnected')
                : t('integrations.sftpModal.form.placeholder.isConnected')
            }
            disabled={!isEnabled}
            required={!isConnected}
          />
          {isConnected && <label className='saivaLabel' style={{marginTop: '10px'}}>*Note: Public key is already applied.</label>}
        </Col>
      </Row>
    </IntegrationModalWrapper>
  )
}

export default SFTPModal
